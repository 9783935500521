<template>
  <v-dialog
      v-model="show"
      class="confirm-dialog"
      bg-transition="fade"
      no-scroll
      @close="hideModal()"
      :classes="{ root: 'modal', content: 'modal-dialog modal-md' }"
  >
    <div class="modal-content border-warning"
    :class="`border-${theme}`">
      <div class="modal-header bg-warning"
      :class="`bg-${theme}`">
        <h5 class="modal-title text-light">Your cart is {{ timerString }}</h5>
        <button
            type="button"
            class="close"
            @click="hideModal()"
            aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <cs-loading v-if="$wait.waiting(['enrolled_courses.*', 'touch.cart'])">Refeshing Cart</cs-loading>
      </div>
      <div class="modal-body text-wrap">
            <p class="lead" v-if="expires > 0">Your cart is {{ timerString}}, if you do not reload the page, pay and enroll in the choices, or otherwise interact with your cart it will expire and all items you have in your cart will be removed.  <strong>You can also <a href="#" @click="refreshCart">Manually Refresh Your Cart</a></strong></p>
            <div v-else class="lead alert alert-danger">Your cart has expired, all items in your cart will be removed.</div>

      </div>
      <div class="modal-footer d-flex justify-content-between">
        <cs-button
            type="secondary"
            data-dismiss="modal"
            icon="cancel"
            @click="hideModal()">
          Close
        </cs-button>
        <cs-button
            v-if="expires > 0"
            :type="theme"
            icon="reload"
            :loading="$wait.is('touch.cart')"
            @click="refreshCart()"
        >
          Refresh Cart
        </cs-button>
        <cs-button
            v-if="!isEmpty(registration)"
            type="success"
            icon="registration"
            @click="goToRegistration()"
        >
          Registration
        </cs-button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  GET_ENROLLED_COURSES_COUNT,
  UPDATE_CART_MODIFIED
} from '@/constants/mutation-types'
import { touchCart } from '@/api/cartAPI'
import { isEmpty } from '@/utilities/utilities'

import { VDialog } from 'vuetensils/src/components'
import { CART_EXPIRE_DANGER, CART_EXPIRE_WARNING } from '@/constants/application'

export default {
  name: 'CartExpiringDialog',
  components: {
    VDialog
  },
  props: {
    expires: {
      type: Number,
      required: true
    },
    useExpirationDialog: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      show: false,
      didShowWarning: false,
      didShowDanger: false,
      timeoutId: null,
      expireDanger: CART_EXPIRE_DANGER,
      expireWarning: CART_EXPIRE_WARNING
    }
  },
  created () {

  },
  mounted () {
    if (!isEmpty(this.program) && this.program.course_hold_time <= CART_EXPIRE_WARNING) {
      this.expireWarning = Math.ceil(this.program.course_hold_time / 2)
      this.expireDanger = Math.ceil(this.program.course_hold_time / 4)
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeoutId)
  },
  watch: {
    expires (expire) {
      // if (this.expires <= 0) {
      //   const vm = this
      //   this.timeoutId = setTimeout(() => {
      //     vm.show = false
      //   }, 3000)
      // }
      if (this.expires <= this.expireDanger) {
        if (!this.didShowDanger) {
          this.didShowDanger = true
          this.postNotification('error', 'Your cart will expire in the next few minutes.')
        }
      } else if (this.expires <= this.expireWarning) {
        if (!this.didShowWarning) {
          this.didShowWarning = true
          this.didShowDanger = false
          this.postNotification('warn', 'Your cart is very close to expiring')
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      cartModified: 'cartModified',
      program: 'program',
      programsEnrolledCourses: 'programsEnrolledCourses',
      registrationId: 'registration_id',
      registration: 'registration'
    }),
    count () {
      if (isEmpty(this.programsEnrolledCourses)) {
        return 0
      }
      return this.programsEnrolledCourses.count
    },
    timerString () {
      if (this.expires <= 0) {
        return 'expired'
      } else if (this.expires <= this.expireDanger) {
        return 'expiring soon'
      } else if (this.expires <= this.expireWarning) {
        return 'expiring'
      }
      return 'expiring'
    },
    theme () {
      if (this.expires <= 0) {
        return 'danger'
      } else if (this.expires <= CART_EXPIRE_DANGER) {
        return 'danger'
      } else if (this.expires <= CART_EXPIRE_WARNING) {
        return 'warning'
      }
      return 'info'
    }
  },
  methods: {
    ...mapActions({
      updateModified: UPDATE_CART_MODIFIED,
      getCartCount: GET_ENROLLED_COURSES_COUNT
    }),
    isEmpty,
    postNotification (type, message) {
      if (this.useExpirationDialog) {
        this.show = true
      } else {
        this.$notify({
          group: 'enrollment',
          type: type,
          title: 'Cart Expiration Notice',
          text: message,
          duration: 10000,
          speed: 1000
        })
      }
    },
    goToRegistration () {
      window.location = '/registrations/view/' + this.registrationId
    },
    hideModal () {
      this.show = false
    },
    refreshCart () {
      const programSlug = isEmpty(this.program) ? null : this.program.slug
      this.$wait.start('touch.cart')
      touchCart(this.registrationId, programSlug)
        .then(response => {
          this.show = false
          this.didShowDanger = false
          this.didShowWarning = false
          this.$wait.end('touch.cart')
        })
        .catch(error => {
          console.error('CartExpiringDialog.refreshCart', error)
          this.$wait.end('touch.cart')
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
