<template>
  <div class="card" :class="'border-' + enrolled_courses.program">
    <h3 class="card-header text-light"
        :class="'bg-' + enrolled_courses.program"
        @click="openProgramPage">
      {{ enrolled_courses.name }}
    </h3>
    <div
        v-if="count === 0"
        class="card-body"
        :class="'bg-' + enrolled_courses.program + '-light'"
    >
      <div class="col-6 text-center mx-auto ">
        <h5 class="text-center">Empty Cart</h5>
        <span class="far fa-shopping-cart fa-6x"></span>
      </div>
    </div>
    <div v-else>
      <transition>
        <div
            v-if="loading"
            class="text-center p-2"
            :class="
            'bg-' +
              enrolled_courses.program +
              '-light text-' +
              enrolled_courses.program
          "
        >
          <cs-loading :theme="enrolled_courses.program">
            Updating {{ enrolled_courses.name }}...
          </cs-loading>
        </div>
      </transition>
      <div v-if="registration.hasGaps" class="card-body">
        <app-gaps :registration="registration"></app-gaps>
      </div>
      <table class="table table-sm table-striped table-card">
        <template v-if="hasCalendars">
          <template v-for="(calendar, id) in calendars">
            <thead :key="'calendar-name-' + id">
            <tr>
              <th colspan="3" :class="'bg-' + enrolled_courses.program">
                <span v-html="calendar.name"></span>
              </th>
            </tr>
            </thead>
            <tbody :key="'calendar-students-' + id">
            <template v-for="student in calendar.students">
              <tr :key="'calendar-students-' + id + '-name-' + student.id">
                <th colspan="3">{{ student.name }}</th>
              </tr>
              <template v-if="!isNil(student.days)">
                <tr
                    :class="'table-' + enrolled_courses.program"
                    :key="'calendar-students-' + id + '-courses-' + student.id"
                >
                  <td colspan="3">Signed up days</td>
                </tr>
                <template v-for="(year, yearNum) in student.days">
                  <template v-for="(month, monthNum) in year">
                    <tr :key="'calendar-students-' + id + '-student-' + student.id + '-year-' + yearNum + '-' + monthNum">
                      <td colspan="3">
                        {{ dateString(dateForMonth(monthNum, yearNum), 'MMMM') }}
                      </td>
                    </tr>
                    <template v-for="(day, dayNum) in month">
                      <template v-if="!isEmpty(day.pre_paids)">
                        <tr
                            v-for="pre_paid in day.pre_paids"
                            class="table-active"
                            :key="'calendar-students-' + id + '-student-' + student.id + '-pre-paids-day-' +
                                dayNum +
                                '-' +
                                pre_paid.course.id
                            "
                        >
                          <td :key="'calendar-students-' + id + '-student-' + student.id + '-' + pre_paid.id">
                              <span class="text-primary">Prepaid: </span
                              ><span v-html="pre_paid.course.name"></span> -
                            {{ pre_paid.course.paid_hours }} -
                            {{ dateString(day.dateForMonth,'M/d') }}
                          </td>
                          <td>
                            Unpaid hours: {{ day.pending_hours.balance }}
                          </td>
                          <td class="currency">
                            {{ currency(day.pending_hours.hourly_fee) }}
                          </td>
                        </tr>
                        <template v-for="enrolled_course in day.courses">
                          <tr
                              :class="{
                                'table-warning': enrolled_course.pending,
                                'table-light': !enrolled_course.pending
                              }"
                                :key="enrolled_course.id"
                          >
                            <td>
                                <span
                                    v-if="!enrolled_course.pending"
                                    class="text-primary"
                                >Enrolled:
                                </span>
                              <span v-html="enrolled_course.course.name"/>
                                <span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                            </td>
                            <td>{{ enrolledTimeSpan(enrolled_course) }}</td>
                            <td>
                              <button
                                  v-if="enrolled_course.pending"
                                  v-on:click.prevent="
                                    removeDay(
                                      student,
                                      enrolled_course.course,
                                      calendar,
                                      enrolled_courses.program,
                                      registration,
                                      $event
                                    )
                                  "
                                  class="btn btn-danger btn-sm"
                              >
                                <font-awesome-icon
                                    icon="minus-square"
                                ></font-awesome-icon>
                              </button>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
              <template v-else-if="!isNil(student.enrolled_courses)">
                <tr
                    :class="'table-' + enrolled_courses.program"
                    :key="'calendar-students-' + id + '-student-' + student.id + '-courses-' + student.id"
                >
                  <td colspan="3">Scheduled days</td>
                </tr>
                <tr
                    v-for="enrolled_course in student.enrolled_courses"
                    :key="enrolled_course.id"
                >
                  <template v-if="!hasHourlyFee(enrolled_course)">
                    <td>
                      {{ dateString(enrolled_course.date, 'M/d') }} -
                      <span v-html="enrolled_course.course.name"></span>
                      &nbsp;<span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                      <small>
                        {{ enrolledTimeSpan(enrolled_course) }}
                      </small>
                        <span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                    </td>
                    <td class="currency">
                      {{ currency(enrolled_course.fee) }}<span
                        v-if="enrolled_course.discount > 0"
                        class="text-success"
                    >/{{ currency(enrolled_course.discount) }}</span
                    ><span
                        v-if="enrolled_course.late_fee > 0"
                        class="text-danger"
                    >/{{ currency(enrolled_course.late_fee) }}</span
                    >
                    </td>
                  </template>
                  <template v-else>
                    <td>
                      {{ dateString(enrolled_course.date ,'M/d') }} -
                      <span v-html="enrolled_course.course.name"></span>
                      &nbsp;
                      <small>
                        {{ enrolledTimeSpan(enrolled_course) }}
                      </small>
                        <span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                    </td>
                    <td class="currency">
                      <template v-if="hasFullHourlyFee(enrolled_course)">
                        <small
                            class="text-muted"
                            title="full fee not taking into acount previously paid"
                        >
                          ({{ currency(enrolled_course.full_hourly_fee) }}) </small
                        >&Delta;
                      </template>
                      {{
                        currency(enrolled_course.hourly_fee)
                      }}<span
                        v-if="enrolled_course.discount > 0"
                        class="text-success"
                    >/{{ currency(enrolled_course.discount) }}</span
                    >
                    </td>
                  </template>
                  <td>
                    <button
                        v-on:click.prevent="
                          removeDay(
                            student,
                            enrolled_course,
                            calendar,
                            enrolled_courses.program,
                            registration,
                            $event
                          )
                        "
                        class="btn btn-danger btn-sm"
                    >
                      <font-awesome-icon
                          icon="minus-square"
                      ></font-awesome-icon>
                    </button>
                  </td>
                </tr>
              </template>
            </template>
            </tbody>
          </template>
        </template>
        <template v-if="hasSessions">
          <template v-for="session in sessions">
            <tr :key="'session-' + session.id">
              <th colspan="3" :class="'bg-' + enrolled_courses.program + '-light'">
                <strong>Session: </strong><span v-html="session.name"></span>
              </th>
            </tr>
            <template v-for="student in session.students">
              <tr :key="'session-' + session.id + '-name-' + student.id">
                <th colspan="3">{{ student.name }}</th>
              </tr>
              <tbody
                  v-if="!isNil(student.enrolled_groups)"
                  :key="'session-' + session.id + '-enrolled-groups-' + student.id"
              >
              <template v-for="group in student.enrolled_groups">
                <tr
                    :class="'table-' + enrolled_courses.program"
                    :key="'session-' + session.id + '-group-' + group.id + '-student-' + student.id"
                >
                  <td colspan="">
                    Block: <span v-html="group.name"></span>&nbsp;<small
                  >{{ timeRange(group.start_time,group.end_time)}}</small
                  >
                  </td>
                  <td class="currency">
                    {{ currency(group.total) }}/<span class="text-success"
                  >{{ currency(group.discountTotal) }} </span
                  >{{ currency((group.total - group.discountTotal)) }}
                  </td>
                  <td>
                    <button
                        v-on:click.prevent="
                          removeGroupFromCard(
                            student,
                            group,
                            enrolled_courses.program,
                            registration,
                            $event
                          )
                        "
                        class="btn btn-danger btn-sm"
                    >
                      <font-awesome-icon
                          icon="minus-square"
                      ></font-awesome-icon>
                    </button>
                  </td>
                </tr>
                <tr
                    v-for="enrolled_course in group.enrolled_courses"
                    :key="enrolled_course.id"
                    :class="{
                      'table-danger': enrolledCourseFull(enrolled_course),
                      'table-warning': enrolledCourseFilling(enrolled_course)
                    }"
                >
                  <td>
                    <span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                    <span v-html="enrolled_course.course.name"/>&nbsp;
                    <span
                        class="badge badge-danger"
                        v-if="enrolledCourseFull(enrolled_course)"
                    >
                        full
                      </span>
                    <span
                        class="badge badge-warning"
                        v-if="enrolledCourseFilling(enrolled_course)"
                    >
                        filling
                      </span>
                    <small
                    >{{timeRange(enrolled_course.course.startTime,enrolled_course.course.endTime)}}</small
                    >
                  </td>
                  <td class="currency">
                    {{
                      enrolled_course.fee | currency
                    }}<span
                      v-if="enrolled_course.discount > 0"
                      class="text-success"
                  >/{{ currency(enrolled_course.discount) }}</span
                  >
                  </td>
                  <td>
                    <button
                        v-on:click.prevent="
                          removeCourse(
                            student,
                            enrolled_course.course,
                            sessionenrolled_courses.program,
                            registration,
                            $event
                          )
                        "
                        class="btn btn-danger btn-sm"
                    >
                      <font-awesome-icon
                          icon="minus-square"
                      ></font-awesome-icon>
                    </button>
                  </td>
                </tr>
              </template>
              </tbody>
              <tbody
                  v-if="!isNil(student.enrolled_courses)"
                  :key="'session-' + session.id + '-enrolled-courses-' + student.id"
              >
              <tr :class="'table-' + enrolled_courses.program">
                <td colspan="3">Courses</td>
              </tr>
              <tr
                  v-for="enrolled_course in student.enrolled_courses"
                  :key="enrolled_course.id"
                  :class="{
                    'table-danger': enrolledCourseFull(enrolled_course),
                    'table-warning': enrolledCourseFilling(enrolled_course)
                  }"
              >
                <template v-if="!hasHourlyFee(enrolled_course)">
                  <td>
                      <span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                    <span v-html="enrolled_course.course.name"/>
                    <span
                        class="badge badge-danger"
                        v-if="enrolledCourseFull(enrolled_course)"
                    >full</span>
                    <span
                        class="badge badge-warning"
                        v-if="enrolledCourseFilling(enrolled_course)"
                    >filling</span>
                    <small>
                      {{ timeRange(enrolled_course.course.startTime ,enrolled_course.course.endTime) }}
                    </small>
                  </td>
                  <td class="currency">
                    {{ currency(enrolled_course.fee) }}<span
                      v-if="enrolled_course.discount > 0"
                      class="text-success"
                  >/{{ currency(enrolled_course.discount) }}</span
                  ><span
                      v-if="enrolled_course.late_fee > 0"
                      class="text-danger"
                  >/{{ currency(enrolled_course.late_fee) }}</span
                  >
                  </td>
                </template>
                <template v-else>
                  <td>
                    <span v-html="enrolledCourseHoldExpired(enrolled_course)" />
                    <span v-html="hourlyName(enrolled_course)"></span>
                    <span
                        class="badge badge-danger"
                        v-if="enrolledCourseFull(enrolled_course)"
                    >
                        full
                      </span>
                    <span
                        class="badge badge-warning"
                        v-if="enrolledCourseFilling(enrolled_course)"
                    >
                        filling
                      </span>
                  </td>
                  <td class="currency">
                    <template v-if="hasFullHourlyFee(enrolled_course)">
                      <small
                          class="text-muted"
                          title="full fee not taking into acount previously paid"
                      >
                        ({{
                          enrolled_course.full_hourly_fee | currency
                        }}) </small
                      >&Delta;
                    </template>
                    {{ currency(enrolled_course.hourly_fee) }}
                    <span
                        v-if="enrolled_course.discount > 0"
                        class="text-success"
                    >/{{ currency(enrolled_course.discount) }}</span
                    >
                  </td>
                </template>
                <td>
                  <button
                      v-on:click.prevent="
                        removeCourseFromCart(
                          student,
                          enrolled_course.course,
                          session,
                          enrolled_courses.program,
                          registration,
                          $event
                        )
                      "
                      class="btn btn-danger btn-sm"
                  >
                    <font-awesome-icon
                        icon="minus-square"
                    ></font-awesome-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </template>
          </template>
        </template>
        <tfoot>
        <tr v-if="pendingFee > 0">
          <td class="text-right">New Fees</td>
          <td class="currency">{{ currency(pendingFee) }}</td>
          <td></td>
        </tr>
        <tr v-if="amountDue > 0">
          <td class="text-right">Current Subtotal</td>
          <td class="currency">
            {{ currency(pendingSubTotal) }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="text-right"><strong>Balance Due</strong></td>
          <td
              class="currency"
              :class="{
                'text-danger': balance > 0,
                'text-success': balance < 0,
                'text-dark': balance === 0
              }"
          >
            {{ currency(balance) }}
          </td>
          <td></td>
        </tr>
        </tfoot>
      </table>
    </div>
    <div v-if="!loading" class="card-footer text-center">
      <button
          v-if="balance > 0"
          v-on:click.prevent="payRegistration"
          class="btn btn-success btn-truncate-text"
      >
        <font-awesome-icon icon="credit-card-front"></font-awesome-icon>
        Pay {{ currency(balance) }} and Enroll
        {{ enrolled_courses.count }}
        {{ plural('course', enrolled_courses.count) }} in
        <span v-html="enrolled_courses.name"/>
      </button>

      <button
          v-else
          v-on:click.prevent="enrollRegistration"
          class="btn btn-info btn-truncate-text"
      >
        <font-awesome-icon icon="user-plus"></font-awesome-icon>
        Enroll {{ enrolled_courses.count }}
        {{ plural('course', enrolled_courses.count) }} in
        <span v-html="enrolled_courses.name"/>
      </button>
    </div>
  </div>
</template>

<script>

import Gaps from '@/components/shared/Gaps'

import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_REGISTRATION } from '@/constants/mutation-types'
import { courseMethods } from '@/components/mixins/course'
import { groupMethods } from '@/components/mixins/group'
import { calendarMethods } from '@/components/mixins/calendar'
import { urlBuilder } from '@/utilities/http-common'
import { currency, plural } from '@/utilities/stringFormatters'

import { dateRange, dateString, dateTimeString, timeRange, timeString } from '@/utilities/dateFormatters'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isNaN from 'lodash/isNaN'
import debounce from 'lodash/debounce'

export default {
  name: 'ProgramSignupCart',
  mixins: [courseMethods, groupMethods, calendarMethods],
  components: {
    appGaps: Gaps
  },
  props: {
    should_update: {
      type: Boolean,
      required: true
    },
    enrolled_courses: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modalShown: false,
      htmlContent: null,
      loading: false,
      localRegistration: {}
    }
  },
  created () {
    if (this.loggedIn) {
      if (
        this.should_update ||
          isEmpty(this.registrationBySlug(this.enrolledCourses.program))
      ) {
        this.updateLocalRegistration()
      } else {
        this.refreshRegistration()
      }
    }
  },
  watch: {
    enrolled_courses () {
      this.updateLocalRegistration()
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'loggedIn',
      year: 'year',
      registrationBySlug: 'registrationBySlug'
    }),
    offset () {
      return {
        top: 60
      }
    },
    count () {
      const enrolledCourses = this.enrolled_courses
      return enrolledCourses.count
    },
    registration () {
      return this.localRegistration
    },
    hasCalendars () {
      const calendars = this.calendars
      if (isEmpty(calendars)) {
        return false
      }
      return true
    },
    calendars () {
      const registration = this.registration
      const pending = registration.pending_enrollments
      if (
        isNil(pending) ||
          isNil(pending.calendars) ||
          isEmpty(pending.calendars)
      ) {
        return {}
      } else {
        return pending.calendars
      }
    },
    hasSessions () {
      const sessions = this.sessions
      if (isEmpty(sessions)) {
        return false
      }
      return true
    },
    sessions () {
      const registration = this.registration
      const pending = registration.pending_enrollments
      if (
        isNil(pending) ||
          isNil(pending.session) ||
          isEmpty(pending.session)
      ) {
        return {}
      } else {
        return pending.session
      }
    },
    balance: function () {
      if (isEmpty(this.registration) || typeof this.registration.balance === 'undefined') {
        return 0
      }
      return this.registration.balance
    },
    amountDue: function () {
      const registration = this.registration
      var result = this.pendingSubTotal + this.pendingFee
      if (!isNil(registration.amountDue)) {
        result = result + registration.amountDue
      }
      return result
    },
    priorAmountDue: function () {
      const registration = this.registration
      var result = 0
      if (!isNil(registration.amountDue)) {
        result = result + registration.amountDue
      }
      return result
    },
    pendingFee: function () {
      const registration = this.registration
      if (!isNil(registration.pendingRegistrationFee)) {
        return registration.pendingRegistrationFee
      }
      return 0
    },
    pendingSubTotal: function () {
      const registration = this.registration
      if (!isNil(registration.pendingSubTotal)) {
        return registration.pendingSubTotal
      }
      return 0
    },
    hasGapsOrOverlaps: function () {
      const registration = this.registration

      return registration.hasGaps || registration.hasOverlaps
    },
    programSlug () {
      return this.enrolled_courses.program
    }
  },
  methods: {
    ...mapActions({
      updateRegistration: UPDATE_REGISTRATION
    }),
    currency,
    plural,
    dateString,
    timeString,
    dateRange,
    timeRange,
    isNil,
    isNaN,
    isEmpty,
    enrolledCourseHoldExpired (enrolledCourse) {
      console.warn('enrolledCourseHoldExpired', enrolledCourse)
      let cartCourse = null
      if (!isEmpty(enrolledCourse.cartCourse)) {
        cartCourse = enrolledCourse.cartCourse
      } else if (!isEmpty(enrolledCourse.cart_course)) {
        cartCourse = enrolledCourse.cart_course
      }
      console.warn('enrolledCourseHoldExpired', cartCourse)
      if (!isEmpty(cartCourse.expired)) {
        const dateString = dateTimeString(cartCourse.expired)
        return `<span class="badge badge-info" title="expired: ${dateString}, your placement in this course is not ensured.">hold expired</span>`
      }
    },
    enrolledCourseFull (enrolledCourse) {
      if (
        !isEmpty(enrolledCourse.course) &&
          enrolledCourse.course.space <= 0
      ) {
        return true
      }
      return false
    },
    enrolledCourseFilling (enrolledCourse) {
      if (
        !isEmpty(enrolledCourse.course) &&
          enrolledCourse.course.space < 5 &&
          enrolledCourse.course.space > 0
      ) {
        return true
      }
      return false
    },
    enrolledCourseWaitlist (enrolledCourse) {
      if (
        !isEmpty(enrolledCourse.course) &&
          enrolledCourse.course.waitlist === 'waitlist'
      ) {
        return true
      }
      return false
    },
    updateLocalRegistration: function () {
      this.loading = true
      const vm = this
      this.updateRegistration({
        programSlug: this.enrolled_courses.program
      }).then(
        () => {
          vm.loading = false
          vm.refreshRegistration()
        },
        e => {
          alert('problem loading registration: ' + e.message)
        }
      )
    },
    removeDay (student, enrolledCourse, calendar, program, registration, event) {
      event.target.disabled = true
      if (!isEmpty(enrolledCourse.cart_course_id)) {
        this.$wait.start(`cart.${this.program.slug}.${enrolledCourse.cart_course_id}`)
        this.removeDayFromCartCourse(student, enrolledCourse.course, enrolledCourse.cart_course_id)
          .then(() => {
            this.$wait.end(`cart.${this.program.slug}.${enrolledCourse.cart_course_id}`)
            this.updateLocalRegistration()
            this.updateEnrolledCourses()
            event.target.disabled = false
          })
          .catch(() => {
            this.$wait.end(`cart.${this.program.slug}.${enrolledCourse.cart_course_id}`)
          })
      } else {
        const course = enrolledCourse.course
        this.removeDayFromCart(student, course, calendar, registration)
          .then(() => {
            this.updateLocalRegistration()
            this.updateEnrolledCourses()
            event.target.disabled = false
          })
          .catch(() => {})
      }
    },
    dateForMonth (month, year) {
      let date = moment()
      date = date.year(year).month(month)
      return date
    },
    debounceUpdateRegistration: debounce(() => {
      this.updateLocalRegistration()
    }, 2000),
    refreshRegistration: function () {
      const enrolledCourses = this.enrolled_courses
      this.localRegistration = this.registrationBySlug(enrolledCourses.program)
    },
    enrolledTimeSpan (enrolledCourse) {
      let start = moment(enrolledCourse.course.startTime)
      let end = moment(enrolledCourse.course.endTime)
      if (!isNil(enrolledCourse.start)) {
        start = moment(enrolledCourse.start)
      }
      if (!isNil(enrolledCourse.end)) {
        end = moment(enrolledCourse.end)
      }
      return start.format('h:mma') + '-' + end.format('h:mma')
    },
    hasHourlyFee: function (enrolledCourse) {
      const b = typeof enrolledCourse.hourly_fee !== 'undefined' && !isNaN(enrolledCourse.hourly_fee)
      // hourly fee is only set if it exists and it could be positive, negative or 0
      return b
    },
    hasFullHourlyFee: function (enrolledCourse) {
      return (
        !isNaN(enrolledCourse.full_hourly_fee) &&
          enrolledCourse.full_hourly_fee > 0
      )
    },
    hourlyName: function (hourlyEnrolled) {
      const course = hourlyEnrolled.course
      var name = course.name
      var days = course.days
      var start = course.startTime
      var end = course.endTime
      if (!isNil(hourlyEnrolled.start)) {
        start = hourlyEnrolled.start
      }
      if (!isNil(hourlyEnrolled.end)) {
        start = hourlyEnrolled.end
      }
      return (
        name + ' <small>(' + timeRange(start, end) + ') ' + days + '</small>'
      )
    },
    openProgramPage () {
      event.target.disabled = true
      const returnTo = window.location.pathname
      if (window.location.pathname.includes(this.enrolled_courses.program)) {
        this.$emit('close')
        return
      }
      const registration = this.registration
      window.location.href = urlBuilder('/' + this.enrolled_courses.program, {
        registration_id: registration.id,
        return_to: returnTo,
        scroll_to: 'pay'
      })
    },
    payRegistration (event) {
      event.target.disabled = true
      const returnTo = window.location.pathname
      const registration = this.registration
      window.location.href = urlBuilder('/registrations/view', {
        registration_id: registration.id,
        return_to: returnTo,
        scroll_to: 'pay'
      })
    },
    enrollRegistration (event) {
      event.target.disabled = true
      const returnTo = window.location.pathname
      const registration = this.registration
      window.location.href = urlBuilder('/registrations/view', {
        registration_id: registration.id,
        return_to: returnTo,
        scroll_to: 'enroll'
      })
    },
    showModal: function () {
      this.modalShown = true
      this.updateRegistrations()
    },
    hideModal () {
      // Stuff
      this.modalShown = false
    }
  }
}
</script>

<style scoped>
.cart {
  top: 100px;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
