<template>
  <span v-if="showTime && !isEmpty(program) && !isEmpty(timer)"
        class="badge"
        :class="displayClass">
    <cs-loading v-if="$wait.is('cart-modified.get')" :sm="true" theme="light"/>
    {{ timer }}
  </span>
  <span v-else-if="!isEmpty(this.program) && !isEmpty(timerString)"
        class="badge"
        :class="displayClass">
    <cs-loading v-if="$wait.is('cart-modified.get')" :sm="true" theme="light"/>
    {{ timerString }}
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_ENROLLED_COURSES, GET_ENROLLED_COURSES_COUNT, UPDATE_CART_MODIFIED } from '@/constants/mutation-types'
import addMinutes from 'date-fns/addMinutes'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { isEmpty } from '@/utilities/utilities'
import debounce from 'lodash/debounce'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { number } from '@/utilities/stringFormatters'
import { CART_EXPIRE_DANGER, CART_EXPIRE_WARNING } from '@/constants/application'
import Program from '@/models/Program'
import throttle from 'lodash/throttle'
import { touchCart } from '@/api/cartAPI'

export default {
  name: 'CartTimer',
  props: {
    expires: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showTime: false, // process.env.MIX_RELEASE_STAGE !== 'production',
      timer: 'tbd',
      intervalId: null,
      timeoutId: null,
      debounceUpdateModified: null,
      throttleTouchCart: null,
      updateInterval: 60 * 1000,
      alertInterval: 3,
      maxRetryUpdateEnrolledCoursesAfterExpire: 5,
      updateEnrolledCoursesTries: 0,
      retryIntervalInSeconds: 5,
      showAlert: true,
      isProduction: process.env.MIX_RELEASE_STAGE === 'production',
      expireDanger: CART_EXPIRE_DANGER,
      expireWarning: CART_EXPIRE_WARNING
    }
  },
  created () {
    this.$emit('update:expires', 10)
    if (!this.isProduction) {
      this.updateInterval = this.updateInterval / 2
    }
    this.updateModified()
      .then(modified => {
        console.log('CartTimer.created', modified)
        if (!isEmpty(this.program)) {
          if (modified === null) {
            this.timer = ''
          } else {
            const holdExpires = addMinutes(modified, this.program.course_hold_time)
            this.$emit('update:expires', differenceInMinutes(holdExpires, new Date()))
            this.timer = formatDistanceToNow(holdExpires)
          }
        }
      })
      .catch(error => {
        console.error('CartTimer.created', error)
      })
    const vm = this
    this.throttleTouchCart = throttle(() => {
      touchCart()
    }, this.updateInterval)
    this.debounceUpdateModified = debounce(() => {
      console.log('CartTimer.debounceUpdateModified')
      if (vm.count > 0) {
        vm.updateModified(vm.registrationId)
      }
    }, this.updateInterval / 4)
    this.intervalId = setInterval(() => {
      if (vm.count > 0) {
        vm.debounceUpdateModified()
      } else {
        vm.updateTimer()
      }
    }, this.updateInterval
    )
  },
  mounted () {
    if (!isEmpty(this.program) && this.program.course_hold_time <= CART_EXPIRE_WARNING) {
      this.expireWarning = Math.ceil(this.program.course_hold_time / 2)
      this.expireDanger = Math.ceil(this.program.course_hold_time / 4)
    }
  },
  beforeDestroy () {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId)
    }
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId)
    }
  },
  watch: {
    isAppIdle (newIdle, oldIdle) {
      if (!newIdle) {
        console.log('CartTimer.onActive watcher')
        this.throttleTouchCart()
      }
    },
    cartModified (newModified) {
      if (newModified === null) {
        this.timer = 'expired'
      } else {
        this.updateTimer()
      }
    },
    timer (newTimer) {
      if (newTimer === 'expired') {
        this.$emit('cart-expired')
        this.updateEnrolledCoursesTries = 0
        this.retryIntervalInSeconds = 5
        this.updateEnrolledCoursesAfterExpire()
      }
    }
  },
  computed: {
    ...mapGetters({
      cartModified: 'cartModified',
      program: 'program',
      registration: 'registration',
      registrationId: 'registration_id',
      programsEnrolledCourses: 'programsEnrolledCourses'
    }),
    programs () {
      const programs = Program.query()
      return programs
        .orderBy('sort')
        .where('active', true)
        .get()
    },
    courseHoldTime () {
      if (!isEmpty(this.program)) {
        return this.program.course_hold_time
      } else {
        const enrolledProgramKeys = Object.keys(this.programsEnrolledCourses)
        const enrolledProgram = enrolledProgramKeys.find(key => {
          return key !== 'count' && 'program' in this.programsEnrolledCourses[key]
        })
        if (enrolledProgram !== undefined) {
          const program = this.programs.find(program => {
            return program.slug === enrolledProgram
          })
          if (program !== undefined) {
            return program.course_hold_time
          }
        }
      }
      return 10
    },
    count () {
      console.log('CartTimer.count', this.programsEnrolledCourses)
      if (isEmpty(this.programsEnrolledCourses)) {
        return 0
      }
      return this.programsEnrolledCourses.count
    },
    timerString () {
      if (this.expires <= 0) {
        return 'expiring'
      } else if (this.expires <= this.expireDanger) {
        return 'expiring soon'
      } else if (this.expires <= this.expireWarning) {
        return ''
      }
      return ''
    },
    displayClass () {
      if (this.expires <= 0) {
        return 'badge-danger'
      } else if (this.expires <= this.expireDanger) {
        return 'badge-danger'
      } else if (this.expires <= this.expireWarning) {
        return 'badge-warning'
      }
      return 'badge-info'
    }

  },
  methods: {
    ...mapActions({
      updateModified: UPDATE_CART_MODIFIED,
      getCartCount: GET_ENROLLED_COURSES_COUNT,
      getEnrolledCourses: GET_ENROLLED_COURSES
    }),
    isEmpty,
    onActive () {
      console.log('CartTimer.onActive method')
    // this.throttleTouchCart()
    },
    onIdle () {
      console.log('CartTimer.onIdle method')
    // this.throttleTouchCart()
    },
    updateEnrolledCoursesAfterExpire () {
      this.getEnrolledCourses()
        .then(result => {
          if (!isEmpty(result) && !isEmpty(result.data) && !isEmpty(result.data.enrolled_courses)) {
            if (result.data.enrolled_courses.count > 0) {
              // take a moment and try to getEnrolledCourses again
              const vm = this
              this.timeoutId = setTimeout(() => {
                if (vm.maxRetryUpdateEnrolledCoursesAfterExpire > vm.updateEnrolledCoursesTries) {
                  vm.updateEnrolledCoursesTries = vm.updateEnrolledCoursesTries + 1
                  vm.retryIntervalInSeconds = vm.retryIntervalInSeconds * 4
                  vm.updateEnrolledCoursesAfterExpire()
                }
              }, vm.retryIntervalInSeconds * 1000)
            }
          }
          console.log('CartTimer.watch.timer', this.timer, this.expires, result)
        })
    },
    updateTimer () {
      console.log('CartTimer.updateTimer', this.cartModified, this.count)
      if (this.cartModified === null) {
        if (this.count === 0) {
          this.timer = ''
        } else {
          this.timer = 'expired'
        }
      } else {
        console.log('CartTimer.updateTimer updateCart else', this.timer, this.expires)
        const holdExpires = addMinutes(this.cartModified, this.courseHoldTime)
        this.$emit('update:expires', differenceInMinutes(holdExpires, new Date()), { roundingMethod: 'ceil' })
        this.timer = formatDistanceToNow(holdExpires)
        const difInSeconds = differenceInSeconds(holdExpires, new Date(), { roundingMethod: 'floor' }) / 60
        console.log('CartTimer.updateTimer updateCart else', this.timer, this.expires, number(difInSeconds, 0, 2))
        if (this.expires < 0) {
          this.timer = 'expired'
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
